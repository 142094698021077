<template>
	<div class="activity" :style="invShow?'padding-bottom:3rem':'padding-bottom:1.3rem'">
		<van-swipe class="activity-swipe" :autoplay="3000">
			<van-swipe-item v-for="(img,index) in activity.banner_images" :key="index">
				<van-image lazy-load :src="img" width="100%"  fit="contain"></van-image>
			</van-swipe-item>
		</van-swipe>

		<van-cell-group>
			<van-cell class="cell-cancel-border">
				<div class="title1 clamp-3">
					<i class="iconfont icon-quntibansui"></i>
					{{ activity.activity_name }}
				</div>
			</van-cell>
			<van-cell class="activity-express">
				<div class="title-bot flex flex-row flex-jusbet font-14">
					<div class="left">
						<i class="iconfont icon-yuedu"></i>
						<span class="text-disabled">{{ activity.scan_num }}</span>
					</div>
					<div class="right" @click="toZan()">
						<i class="iconfont icon-dianzan1" :class="activity.is_zan==1?'text-theme2':''"></i>
						<span :class="activity.is_zan==1?'text-theme2':'text-grey'">{{ activity.like_num }}</span>
					</div>
				</div>
			</van-cell>

			<van-cell value icon>
				<div class="title-bot flex flex-row font-14">
					<i class="iconfont icon-shijian"></i>
					<span class="van-cell-text">{{ activity.start_time }}至{{activity.end_time}}</span>
				</div>
			</van-cell>

			<van-cell value icon>
				<div class="title-bot flex flex-row font-14">
					<i class="iconfont icon-p-cut-time"></i>

					<span class="font-14 text-base van-cell-text">报名截止时间{{ activity.enroll_end_time }}</span>
				</div>
			</van-cell>

			<van-cell is-link @click=" layerShow = true">
				<i class="iconfont icon-jinqian"></i>
				<span class="van-cell-text text-theme2" v-if="activity.card_list && activity.card_list.length > 0">
					{{activity.card_list[0].card_name}}
					{{activity.card_list[0].amount == 0 ? '免费' : '￥' + activity.card_list[0].amount}}
				</span>
				<span class="van-cell-text text-theme2" v-else>当前暂无可售门票</span>
			</van-cell>

			<van-cell vis-link v-if="activity.is_show_person_switch==1">
				<i class="iconfont icon-baoming"></i>
				<span class="font-16 text-base">已有{{ activity.enroll_num }}人报名</span>
			</van-cell>
		</van-cell-group>

		<!-- 爆款专区 -->
		<van-cell-group class="font-16 mt5" v-for="(item1, index1) in goodsList" :key="index1">
			<van-cell :title=" item1.title" title-class="title-class" value-class="value-class" :value="item1.title2" is-link
			 @click="sorry(index1)" />
			<div class="goods-container bg-white flex flex-column">
				<div class="goods-wrap weui-cell flex flex-row flex-nowrap flex-alicen mt5 van-cell" v-for="(item2, index2) in item1.goods"
				 :key="index2" @click="moldbabydetail(item2.id)">
					<div class="pic-wrap">
						<van-image class="pic" fit="fit" :src="item2.images.length > 0 ? item2.images[0] : ''"></van-image>
						<i class="iconfont icon-bao text-red"></i>
					</div>
					<div class="text-wrap mr5 ml5" style="width: 2rem;">
						<div class="producter clamp-1 font-14 mt10">{{ item2.moldbaby_name}}</div>
						<div class="detail text-grey font-12 clamp-3 overhidden" style="height: 1rem;padding-top: 0.1rem;">{{item2.moldbady_desc}}</div>
						<div class="price clamp-1 text-base font-12" style="padding-top: 0.1rem;">零售价：￥{{item2.retail_price}}</div>
					</div>
					<div class="h-right">
						<div class="h-price">￥{{item2.real_price}}</div>
						<van-progress class="h-progress" :percentage="item2.rate" />
						<div class="h-sale">已售 {{item2.rate | number()}}%</div>
						<div class="h-btn" :style="item2.rate >= 100 || item2.inventory == 0 ? 'background-color:gray;':''">{{item2.rate >= 100 || item2.inventory == 0 ? '已抢完':'立即购买'}}</div>
					</div>
				</div>

				<div class="load-more mr-bottom" v-if="item1.hasmore" @click="loadMore(index1)">点击加载更多</div>
			</div>
		</van-cell-group>

		<!-- 团购专区 -->
		<van-cell-group class="font-16 mt5" v-if="activity.is_group_switch>0">
			<van-cell title="拼团专区" title-class="title-class" value-class="value-class" value="更多拼团" is-link @click="moregroupbuy()" />
			<div class="goods-container bg-white flex flex-column">
				<div class="goods-wrap weui-cell flex flex-row flex-nowrap flex-alicen mt5 van-cell" v-for="(item3, index3) in group_list"
				 :key="index3" @click="groupbuydetail(item3.id)">
					<div class="pic-wrap">
						<van-image class="pic" fit="fit" :src="item3.images.length > 0 ? item3.images[0] : ''"></van-image>
						<div class="icon-my-tuan">团</div>
					</div>
					<div class="text-wrap mr5 ml5" style="width: 2rem;">
						<div class="producter clamp-1 font-14 mt10">{{item3.good_name}}</div>
						<div class="detail text-grey font-12 clamp-3 overhidden" style="height: 1rem;padding-top: 0.1rem;">{{item3.description}}</div>
						<div class="price clamp-1 text-base font-12" style="padding-top: 0.1rem;">零售价：￥{{item3.alone_price}}</div>
					</div>
					<div class="h-right">
						<div class="h-price">￥{{item3.groupbuy_price}}</div>
						<van-progress class="h-progress" :percentage="item3.percent" />
						<div class="h-sale">已售 {{item3.percent | number()}}%</div>
						<div class="h-btn" :style="item3.percent >= 100 || item3.stock == 0 ? 'background-color:gray;':''">{{item3.percent >= 100 || item3.stock == 0 ? '已抢完':'立即购买'}}</div>
					</div>
				</div>

				<div class="load-more mr-bottom" v-if="group_finished==false" @click="getGroupbuyList()">点击加载更多</div>
			</div>
		</van-cell-group>

		<!-- 商品详情 -->

		<div class="detail-section mt10" style="font-size:0;">
			<!-- 折叠面板 -->
			<van-collapse v-model="activeNames">
				<van-collapse-item title="活动详情" name="1">
					<van-image v-for="(img,index) in activity.detail_images" style="margin-top: -3px" :key="index" lazy-load :src="img"
					 width="100%" fit="contain">
						<template v-slot:loading>
							<van-loading type="spinner" size="20" />
						</template>
					</van-image>

				</van-collapse-item>
			</van-collapse>

		</div>

		<!-- 已报名 -->
<!--		<van-cell-group v-if="activity.is_show_person_switch">-->
<!--			<van-cell title-class="title-class1" value-class="value-class1" is-link :title="'已报名('+memberList.length+')'">-->
<!--				<div>-->
<!--					<span class="custom-title" @click="toMoreMember">更多报名</span>-->
<!--				</div>-->
<!--			</van-cell>-->

<!--			<van-grid :border="false" :column-num="5" v-if="memberList.length>0">-->
<!--				<van-grid-item v-for="(item,index) in memberList" :key="index" v-if="index < 5">-->
<!--					<van-image round show-loading lazy-load :src="item.avatar" width="1.2rem" height="1.2rem">-->
<!--						<template v-slot:loading>-->
<!--							<van-loading type="spinner" size="20" />-->
<!--						</template>-->
<!--					</van-image>-->
<!--					<label class="name font-12 clamp1 overhidden my-color-gray" style="width: 1.3rem;text-align: center;padding-top: 2px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{item.nickname}}</label>-->
<!--				</van-grid-item>-->
<!--			</van-grid>-->

<!--			<div class="van-cell text-grey" style="text-align: center;display: block;" v-else>暂无更多报名，我来第一个报名！</div>-->
<!--		</van-cell-group>-->

		<!-- 评论 -->
<!--		<van-cell-group class="comment-container">-->
<!--			<van-cell title="评论" title-class="title-class1" value-class="value-class1">-->
<!--				<div>-->
<!--					<span class="custom-title" style="color: #3487E2;" @click="ShowComment">发表评论</span>-->
<!--				</div>-->
<!--			</van-cell>-->

<!--			<div class="comment-wrap van-cell" v-for="(item,index) in commentList.data" :key="index" v-if="commentList.data  && commentList.data.length>0 ">-->
<!--				<div class="left">-->
<!--					<van-image round show-loading width="1.1rem" height="1.1rem" :src="item.avatar">-->
<!--						<template v-slot:loading>-->
<!--							<van-loading type="spinner" size="20" />-->
<!--						</template>-->
<!--					</van-image>-->
<!--				</div>-->
<!--				<div class="right ml5">-->
<!--					<div class="name text-base font-14 clamp-1">{{item.nickname}}</div>-->
<!--					<div class="time text-base font-12 clamp-1 text-grey">{{item.createtime}}</div>-->
<!--					<div class="content text-base font-14 clamp-2">{{item.content}}</div>-->
<!--				</div>-->
<!--			</div>-->
<!--			<div v-if="commentList.data.length>0">-->
<!--				<div class="load-more mr-bottom van-cell font-16 my-color-gray" v-if="commentList.has_more " @click="toCommentPage">查看更多</div>-->
<!--				<div class="load-more mr-bottom van-cell font-16 text-grey" v-else>没有更多了</div>-->
<!--			</div>-->

<!--			<div class="van-cell text-grey" style="text-align: center;display: block;" v-if="commentList.data.length==0">暂无评论，我来发表第一条评论！</div>-->
<!--		</van-cell-group>-->

		<!--占位-->
		<div style="height: 1.5rem"></div>

		<!-- 邀请你参加活动 -->
		<div class="inv-info van-cell flex flex-row flex-alicen" v-show="invShow">
			<!-- 663300 -->
			<div class="inv-wrap flex flex-row flex-alicen" @click="toNav(4)">
				
				<van-image width="40px" height="40px" :src="activity.superior_info ? activity.superior_info.avatar : default_avatar" round- lazy-load show-loading fit="cover" class="avatar">
					<template v-slot:loading>
						<van-loading type="spinner" size="20" />
					</template>
				</van-image>

				<span class="name font-16 text-theme3 ml5 mr5">{{activity.superior_info ? activity.superior_info.nickname : ''}}</span>
				<span class="font-16 text-white">{{activity.superior_info ? '邀请您参加活动' : '快来参加活动吧'}}</span>
			</div>

			<i class="iconfont icon-chacha" @click="toInvClose"></i>

		</div>

		<!-- 底部导航 -->
		<div class="footer-action">
			<div class="action-wrap" @click="toCellection">
				<i class="iconfont icon-wuxiaoxing-kong" :class="activity.is_collect?'text-theme3':''"></i>
				<div class="font-12" :class="activity.is_collect?'text-theme3':''">{{activity.is_collect ? "已收藏" : '收藏'}}</div>
			</div>

			<div class="action-button">
				<div v-if="activity.is_enroll==0">
					<van-button v-if="activity.activity_status==0" class="bg-disabled text-white font-18" type="default">未发布</van-button>
					<van-button v-else-if="activity.activity_status==1" class="text-white font-18 bg-theme-color" type="default"
					 @click="toJoin">我要报名</van-button>
				</div>

				<van-button v-else class="text-white font-18 " type="warning" @click="toNav(5)">报名信息</van-button>
			</div>
		</div>

		<!-- 导航 -->

		<div :class="['nav-section','animated',navShow?'slideInRight2':'slideOutRight2']" v-show="activity.is_enroll > 0 || activity.member_info || activity.is_moldbaby>0">
			<div class="show-btn text-white font-18" @click="toggleNav">{{navShow ? '收起' : '导航'}}</div>
			<div class="nav-container flex flex-row flex-alicen bg-white">
				<div class="nav-wrap" @click="toNav(5)" v-show="activity.is_enroll>0 ">
					<i class="iconfont icon-baoming3"></i>
					<span class="text-base font-12">报名信息</span>
				</div>
				<div class="nav-wrap" @click="toNav(6)" v-show="activity.is_enroll>0 ||activity.is_moldbaby>0 ">
					<i class="iconfont icon-dingdan1"></i>
					<span class="text-base font-12">我的订单</span>
				</div>
				<div class="nav-wrap" @click="toNav(7)" v-show="activity.is_enroll>0 ">
					<i class="iconfont icon-dianzipiao"></i>
					<span class="text-base font-12">电子票</span>
				</div>
				<div class="nav-wrap" @click="toNav(4)" v-show="activity.is_enroll>0 ">
					<i class="iconfont icon-yanpiao"></i>
					<span class="text-base font-12">邀请函</span>
				</div>
			</div>
		</div>
		<van-overlay :show="navShow" class="nav-layer" />

		<!-- 卡券遮罩显示 -->
		<van-overlay :show="layerShow" @click=" layerShow=false ">
			<div class="layer-wrapper">
				<div class="block font-14" v-for="(item,index) in activity.card_list" :key="index">
					<i class="iconfont icon-jinqian"></i>
					<span class="van-cell-text text-theme3" v-if="item">
						{{item.card_name}}
						{{item.amount == 0 ? '免费' : '￥' + item.amount}}
					</span>
				</div>
			</div>
		</van-overlay>
		<!-- 发布评论 -->
		<van-popup v-model="commentShow" class="commit-modal">
			<div class="title2">请填写评论内容</div>
			<van-field v-model="message" rows="10" autosize type="textarea" maxlength="100" placeholder="请输入留言" show-word-limit
			 class="field" />
			<div class="button-wrap van-hairline--surround">
				<van-button type="primary" class="cancel text-base van-hairline--right" @click="HideComment">取消</van-button>
				<van-button type="primary" class="commit text-theme2 van-hairline--left" @click="toCommit">提交</van-button>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {
		Tag,
		Button,
		Col,
		Icon,
		Image,
		Cell,
		CellGroup,
		Swipe,
		Field,
		Overlay,
		Toast,
		Progress,
		Divider,
		SwipeItem,
		Popup,
		Grid,
		GridItem,
		GoodsAction,
		GoodsActionIcon,
		GoodsActionButton,
		Collapse,
		CollapseItem,
		Loading,
		CountDown
	} from "vant";
	// 导入我们的api接口
	import {
		activity_detail,
		activity_addlike,
		activity_enroll_list,
		activity_enroll_morelist,
		add_comment,
		comment_list,
		activity_addcollect,
		user_marketing_hot_goodslist, //爆款列表
		groupbuy_user_getgoodslist, //团购
		getsysteminfo

	} from "@/request/api";

	import wx from "@/request/utils/wechat";
	import cache from '@/request/utils/cache';
	import store from '@/store/index';

	export default {
		components: {
			[Tag.name]: Tag,
			[Button.name]: Button,
			[Col.name]: Col,
			[Icon.name]: Icon,
			[Divider.name]: Divider,
			[Image.name]: Image,
			[Progress.name]: Progress,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Swipe.name]: Swipe,
			[Field.name]: Field,
			[Overlay.name]: Overlay,
			[SwipeItem.name]: SwipeItem,
			[Popup.name]: Popup,
			[Grid.name]: Grid,
			[GridItem.name]: GridItem,
			[GoodsAction.name]: GoodsAction,
			[GoodsActionIcon.name]: GoodsActionIcon,
			[GoodsActionButton.name]: GoodsActionButton,
			[Collapse.name]: Collapse,
			[CollapseItem.name]: CollapseItem,
			[Loading.name]: Loading,
			[CountDown.name]: CountDown
		},
		data() {
			return {
				time: 30 * 60 * 60 * 1000,
				activeNames: ["1"],
				sponsor: {
					banner_image: [],
					detail_images: []
				}, //主办方
				activity: [],
				goodsList: [],
				memberList: {
					total: 250,
					member: [{
							user_id: 1,
							name: "测试",
							src: "https://img.yzcdn.cn/vant/cat.jpeg"
						},
						{
							user_id: 1,
							name: "测试",
							src: "https://img.yzcdn.cn/vant/cat.jpeg"
						},
						{
							user_id: 1,
							name: "测试",
							src: "https://img.yzcdn.cn/vant/cat.jpeg"
						},
						{
							user_id: 1,
							name: "测试",
							src: "https://img.yzcdn.cn/vant/cat.jpeg"
						},
						{
							user_id: 1,
							name: "测试",
							src: "https://img.yzcdn.cn/vant/cat.jpeg"
						}
					],
					hot_loading: false
				},
				commentList: {
					data: []
				},
				size: 20, //奖励列表
				page: 1, //奖励列表
				followText: ["+关注", "已关注"],
				isfollowed: false,
				layerShow: false,
				moreShow: true,
				invShow: true,
				navShow: false,
				right: 0,
				commentShow: false,
				message: "",
				test: 0,
				superior: 0, //

				// 团购的参数
				group_size: 6,
				group_finished: false,
				group_loading: false,
				group_list: [],
				default_avatar: '',
			};
		},
		created: function() {
			var systemInfo = cache.get('systemInfo');
			if (!systemInfo){
				getsysteminfo({}).then(res => {
				    if (res.code == 1) {
				        store.commit('UpdateSystemInfo', res.data)
						this.default_avatar = res.data.defaultAvatar
				    }
				})
			}else{
				systemInfo = JSON.parse(systemInfo)
				this.default_avatar = systemInfo.defaultAvatar
				
			}
		},
		mounted: function() {
			let id = this.$route.query.id;
			if (this.$route.query.superior){
				this.superior = this.$route.query.superior
			}
			
			//测试数据
			this.loadData(id);
		},
		update: function() {},

		methods: {
			async loadData(cid) {
				let that = this;

				//1.获取活动详情
				activity_detail({
					activity_id: cid,
					superior: this.superior
				}).then(res => {
					// console.log(res.data)
					if (res.code == 1) {
						that.activity = res.data;

						document.title = that.activity.activity_name;

						//登录后进行签名操作

						var url = window.location.host + this.$router.history.current.fullPath;
						//初始化分享
						wx.share(this.activity.share);
						//获取评论
						this.initCommission();

						if (this.activity.is_explosive_switch > 0) {
							//获取爆款数据
							let goods_item = {
								title: "爆款专区",
								title2: "更多爆款",
								page: 1,
								size: 20,
								type: 1,
								hasmore: true,
								goods: []
							};
							this.goodsList.push(goods_item);
							this.loadMoldbaby(0);
						}

						if (this.activity.is_group_switch > 0) {
							// 获取团购数据
							this.getGroupbuyList()
						}

					} else {
						that.$dialog({
							message: res.msg
						});
					}
				});

				//4.报名列表
				activity_enroll_list({
						id: this.$route.query.id
					})
					.then(res => {
						this.hideLoading();
						if (res.code == 1) {
							this.memberList = res.data.list;
						} else {
							this.$dialog({
								message: res.msg
							});
						}
					})
					.catch(err => {
						this.$dialog({
							message: "报名列表请求有误,请重新刷新页面"
						});
					});
			},
			loadMoldbaby(index) {
				if (this.hot_loading == true) {
					return false
				}
				if (!this.goodsList[index].hasmore) {
					return;
				}
				if (this.goodsList[index].type == 1) {
					this.hot_loading = true
					user_marketing_hot_goodslist({
						rid: this.activity.id,
						page: this.goodsList[index].page,
						size: this.goodsList[index].size,
						is_recomment: 1
					}).then(res => {
						if (res.code == 1) {
							//  还有更多数据page++
							if (res.data.has_more == true) {
								this.goodsList[index].page++;
							} else {
								this.goodsList[index].hasmore = false; //没有数据
							}
							this.goodsList[index].goods = this.goodsList[index].goods.concat(res.data.data)
							this.hot_loading = false
						}
					}).catch(err => {});
				}
			},
			moldbabydetail(id) {
				// if (!this.activity.is_enroll > 0) {
				// 	this.$dialog({
				// 		message: '您还未报名，请先报名'
				// 	})
				// 	return false
				// }
				this.$router.push({
					path: '/activity/marketing/hot/detail',
					query: {
						id: id,
						activity_id: this.activity.id
					}
				})
			},

			formatPrice() {
				return "¥" + (this.activity.price / 100).toFixed(2);
			},

			onClickCart() {
				this.$router.push("cart");
			},
			loadMore(index) {
				this.loadMoldbaby(index);
			},

			sorry(index1) {
				this.$router.push({
					path: '/activity/marketing/hot/more',
					query: {
						id: this.activity.id
					}
				})
			},
			toggleNav() {
				this.navShow = !this.navShow;
			},
			initCommission() {
				let param = {
					activity_id: this.activity.id,
					page: 1
				};
				comment_list(param)
					.then(res => {
						let data = res.data;
						this.commentList = data;
					})
					.catch(error => {
						this.commentList = false;
					});
			},
			toJoin() {
				if (this.activity.card_list.length == 0){
					this.$dialog({
						message: '当前暂无可售门票'
					})
					return
				}
				this.$router.push({
					path: "/activity/enroll",
					query: {
						id: this.activity.id
					}
				});
			},
			toZan() {
				//点赞接口
				activity_addlike({
						activity_id: this.activity.id,
						user_id: this.$store.state.userInfo.id
					})
					.then(res => {
						if (res.code == 1) {
							if (res.data == null) {
								this.activity.is_zan = 0;
								this.activity.like_num--;
								Toast.success({
									message: "成功取消点赞"
								});
							} else {
								this.activity.is_zan = 1;
								this.activity.like_num++;
								Toast.success({
									message: "成功点赞"
								});
							}
						}
					})
					.catch(err => {});

			},
			//观看直播
			toWatch() {
				if (this.activity.islive > 0 && this.activity.liveinfo.is_start > 0) {
					this.$router.push({
						path: '/webcast/index',
						query: {
							id: this.activity.id,
							room_id: this.activity.liveinfo.id,
						}
					});
				}
			},
			//关闭邀请人
			toInvClose() {
				this.invShow = false;
			},
			//跳到更多已报名会员页面
			toMoreMember() {
				this.$router.push({
					path: "/activity/member",
					query: {
						id: this.activity.id
					}
				});
			},
			//显示评论弹窗
			ShowComment() {
				this.commentShow = true;
				this.message = "";
			},

			HideComment() {
				this.commentShow = false;
				this.message = "";
			},
			//跳转至评论页
			toCommentPage() {
				this.$router.push({
					path: "/activity/comment/" + this.activity.id
				});
			},
			//取消评论
			toCancel() {
				this.commentShow = false;
				this.message = "";
			},
			//提交评论
			toCommit() {
				if (this.message == "") {
					Toast.fail("请填写评论内容");
					return;
				}
				add_comment({
						activity_id: this.activity.id,
						content: this.message
					})
					.then(res => {
						if (res.code == 1) {
							let commission = res.data;
							this.commentList.data.push(commission);
							Toast.success("评论成功");
							this.HideComment();
						} else {
							this.$dialog({
								title: "提示",
								message: res.msg
							});
						}
					})
					.catch(error => {
						this.HideComment();
						Toast.fail("服务器响应失败");
					});
			},
			//收藏活动
			toCellection() {
				let param = {
					activity_id: this.activity.id
				};
				activity_addcollect(param)
					.then(res => {
						if (res.code == 1) {
							if (!this.activity.is_collect) {
								this.activity.is_collect = 1;
								Toast({
									message: "收藏成功",
									icon: "wechat"
								});
							} else {
								this.activity.is_collect = 0;
								Toast({
									message: "取消收藏",
									icon: "wechat"
								});
							}
						} else {
							this.$toast.fail("操作失败");
						}
					})
					.catch(error => {
						this.$toast.fail("操作失败");
					});
			},
			//发布活动
			toAddActivity() {
				this.$router.push({
					path: "/activity/cardbag",
					query: {}
				});
			},
			//导航跳转
			toNav(i) {
				let path = "";

				if (i == 1) {
					path = "/user/manage/activity";
				} else if (i == 4) {
					if (this.activity.is_enroll > 0) {
						path = "/activity/invitation"
					} else {
						this.$toast({
							message: "尚未报名该活动！",
							icon: "warning"
						});
						return false
					}
				} else if (i == 5) {
					//报名信息
					path = "/activity/enroll/message";
					this.$router.push({
						path: path,
						query: {
							id: this.activity.id,
							name: this.activity.activity_name
						}
					});
				} else if (i == 6) {
					//我的订单
					path = "/activity/enroll/myorder";
					this.$router.push({
						path: path,
						query: {
							id: this.activity.id
						}
					});
				} else if (i == 7) {
					//电子票
					if (this.activity.ticket_id == '' || this.activity.ticket_id == null) {
						this.$toast({
							message: "尚未报名该活动！",
							icon: "warning"
						});
						return
					}
					path = "/activity/enroll/ticket";
					this.$router.push({
						path: path,
						query: {
							id: this.activity.ticket_id
						}
					});
				}
				if (i < 5) {
					this.$router.push({
						path: path,
						query: {
							id: this.activity.id
						}
					});
				}
			},

			// 更多团购
			moregroupbuy() {
				this.$router.push({
					path: '/activity/marketing/group/more',
					query: {
						id: this.activity.id
					}
				})
			},
			// 商品详情
			groupbuydetail(id) {
				this.$router.push({
					path: '/activity/marketing/group/detail',
					query: {
						id: id,
						activity_id: this.activity.id
					}
				})
			},
			// 团购商品
			getGroupbuyList() {
				if (this.group_finished) {
					return false
				}
				if (this.group_loading) {
					return false
				}
				this.group_loading = true //加载中
				groupbuy_user_getgoodslist({
					activity: this.activity.id,
					size: this.group_size,
				}).then(res => {
					this.group_loading = false // 加载结束
					if (res.code == 1) {
						if (res.data.has_more == false) {
							this.group_finished = true
						} else {
							this.group_size = this.group_size + this.group_size
							this.group_finished = false;
						}
						for(var i = 0;i < res.data.data.lenth;i++){
							var imgs = res.data.data[i].images.split(',')
							if (imgs.length > 0){
								res.data.data[i].images = imgs[0]
							}
						}
						this.group_list = res.data.data
						
					} else {
						this.$dialog({
							message: res.msg
						});
						this.loading = false;
						this.finished = true
					}
				}).catch(err => {
					this.group_loading = false // 加载结束
					console.log(err)
					this.$dialog({
						message: "团购数据获取失败，请重新刷新"
					})
				});
			},
		},
		filters: {
			// 用于已售数量的百分比
			//保留2位小数点过滤器 不四舍五入
			number(value) {
				var toFixedNum = Number(value).toFixed(3);
				var realVal = toFixedNum.substring(0, toFixedNum.toString().length - 1);
				return realVal;
			},
			dataF: function(timestamp) {
				var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var Y = date.getFullYear() + '-';
				var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				var D = date.getDate() + ' ';
				var h = date.getHours() + ':';
				var m = date.getMinutes() + ':';
				var s = (date.getSeconds() + 1 < 10 ? '0' + (date.getSeconds() + 1) : date.getSeconds());
				return Y + M + D + h + m + s;
			}
		}
	};
</script>

<style lang="less">
	.activity {
		padding-bottom: 50px;
		overflow: hidden;

		.activity-swipe {
			font-size: 0;
		}

		.commit-modal {
			display: flex;
			flex-flow: column nowrap;

			.title2 {
				padding: 10px 0;
				text-align: center;
			}

			.field {
				width: 9rem;

				//  height: 6rem;
				.van-field__control {
					color: black;
					border: 0.5px solid rgba(0, 0, 0, 0.1);
				}
			}

			.button-wrap {
				width: 100%;
				display: flex;
				flex-flow: row nowrap;

				.cancel,
				.commit {
					background-color: white;
					flex: 1;
					font-size: 15px;
					border: none;
				}
			}
		}

		// 导航
		.right-in {
			right: 0 !important;
		}

		.right-out {
			// right: -280px !important;
			right: calc(-100% - 45px) !important;
		}

		.right2-out {
			// right: -209px !important;
			right: calc(-100% - 45px) !important;

		}

		.nav-section {
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 900;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			transition: all 0.5s ease-in;

			.show-btn {
				background-color: rgba(0, 0, 0, 0.8);
				border-radius: 5%;
				padding: 5px 8px;
			}

			.nav-container {
				border-radius: 2%;
				flex-wrap: wrap;
				max-width: 282px;
				min-width: 282px;
				// min-width: 211px;

				.nav-wrap {
					display: flex;
					flex-flow: column nowrap;
					align-items: center;
					padding: 10px;

					.iconfont {
						font-size: 28px;
						color: #3d3d3d;
					}

					span {
						width: 50px;
						display: block;
						text-align: center;
						margin-top: 5px;
						height: 16px;
					}
				}
			}
		}

		.nav-layer {
			z-index: 888;
		}

		//评论
		.comment-wrap {
			display: flex;
			flex: row nowrap;
			align-content: center;

			.left {
				align-items: center;
			}

			.right {
				display: flex;
				flex-flow: column nowrap;
			}
		}

		//奖励
		.reward-wrap {
			position: fixed;
			top: 0;
			left: 0;
			display: flex;
			flex-flow: row nowrap;
			opacity: 0;

			.text-wrap {
				height: 30px;
				line-height: 30px;
				margin-left: 5px;
				background-color: rgba(0, 0, 0, 0.3);
				display: flex;
				flex-flow: row nowrap;
				padding: 2px 5px;
				border-radius: 3%;

				.t1 {
					max-width: 210px;
				}
			}
		}

		//邀请人信息
		.inv-info {
			position: fixed;
			bottom: 50px;
			-webkit-box-pack: justify;
			justify-content: space-between;
			background-color: rgba(0, 0, 0, 0.8);

			.van-icon {
				font-size: 36px !important;
			}
		}

		//底部按钮
		.footer-action {
			position: fixed;
			bottom: 0;
			left: 0;
			display: flex;
			flex-flow: row nowrap;
			background-color: white;
			height: 50px;
			width: 100%;
			overflow: hidden;

			// &:before {
			// 	position: absolute;
			// 	box-sizing: border-box;
			// 	content: " ";
			// 	pointer-events: none;
			// 	width: 100%;
			// 	right: 0;
			// 	top: 0;
			// 	border-bottom: 0.02667rem solid #ebedf0;
			// }

			.action-wrap {
				flex: 1;
				display: flex;
				flex-flow: column nowrap;
				height: 50px;
				align-items: center;
				justify-content: center;

				.is_collect {
					color: ;
				}
				
				.icon-wuxiaoxing-kong{
					margin-right: 0;
				}
			}

			.border-right {
				border-right: 0.5px solid rgba(0, 0, 0, 0.1);
			}

			.action-button {
				flex: 2;
				height: 50px;

				.van-button {
					width: 100%;
					height: 50px;
				}
			}

			.iconfont {
				font-size: 20px;
			}
		}

		//轮播
		&-swipe {
			img {
				width: 100%;
				display: block;
				max-height: 300px;
			}
		}

		&-title {
			font-size: 16px;
		}

		&-price {
			color: #f44;
		}

		&-express {
			color: #999;
			font-size: 12px;
			padding: 5px 15px;
		}

		&-cell-group {
			margin: 15px 0;

			.van-cell__value {
				color: #999;
			}
		}

		&-tag {
			margin-left: 5px;
		}

		//观看直播按钮
		.towatch {
			margin-right: 10px;
		}

		//关注 联系按钮
		.author-action {
			justify-content: space-evenly;
		}

		//商品区
		.title2 {
			margin-top: 10px;

			.van-cell__value {
				color: #3487e2;
			}

			span {
				font-size: 14px;
			}

			.van-cell__right-icon {
				color: #3487e2;
			}
		}

		.goods-wrap {
			line-height: 0.5rem !important;

			.pic-wrap {
				width: 85px;
				height: 85px;
				flex: 1;
				position: relative;
				border: 1px solid rgba(0, 0, 0, 0.1);
				overflow: hidden;

				.pic {
					width: 85px;
					height: 85px;
				}

				.icon-bao {
					position: absolute;
					border-radius: 3px;
					margin-left: -2px;
					top: 0;
					left: 0;
					font-size: 20px !important;
					background-color: white;
				}

				.icon-my-tuan {
					position: absolute;
					border-radius: 3px;
					margin-left: -2px;
					top: 0;
					left: 0;
					font-size: 14px !important;
					color: white;
					font-weight: bold;
					width: 20px;
					height: 20px;
					text-align: center;
					line-height: 21px;
					background-color: #1AA5DD;
				}
			}

			.text-wrap {
				flex: 2;
				height: 100px;
			}

			.action-wrap {
				flex: 1;
				height: 100%;
				align-content: space-between;

				.progress-wrap {
					width: 100%;

					.van-progress {
						height: 13px !important;
						border-radius: 1em;

						.van-progress__pivot {
							display: none !important;
							min-width: 1em;
							padding: 2px 0.13333rem;
							color: #fff;
							font-size: 0.1rem;
							line-height: 0.8;
							border-radius: 1em;
							left: 17px;
						}
					}
				}

				.button-wrap {
					width: 100%;

					button {
						padding: 5px 8px;
						border-radius: 5px;
					}
				}
			}
		}

		// 没有更多了
		.load-more {
			display: block !important;
			margin: 0 auto;
			padding: 15px 10px;
			text-align: center;
		}

		// 详情
		.detail-section {
			overflow: hidden;

			//修改折叠面板padding
			.van-collapse-item__content {
				padding: 0;
				font-size: 0;
			}

			.mt60 {
				margin-top: -30%;
			}

			.van-cell {
				display: block !important;
				text-align: center;
				transition: all 2s ease;

				&:after {
					position: absolute;
					box-sizing: border-box;
					content: " ";
					pointer-events: none;
					top: -50%;
					right: -50%;
					bottom: -50%;
					left: -50%;
					border-bottom: 2px solid #ebedf0;
					-webkit-transform: scale(0.5);
					transform: scale(0.5);
				}
			}
		}

		// 遮罩
		.layer-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			flex-flow: column;

			.block {
				width: 270px;
				height: auto;
				background-color: #fff;
				display: flex;
				align-items: center;
				padding: 10px 20px;
				// border-radius: 5px;
			}
		}
	}

	// 积分
	.jidou {
		width: 1.3rem;
		margin: 0 9px;
		text-align: center;
		// float: left;

		.jidou-icon {
			width: 100%;
			height: 1.3rem;
			border-radius: 50%;

			i {
				font-size: 0.8rem;
				line-height: 1.4rem;
				margin-left: 5px;
				color: #ff9900;
			}
		}

		.jidou-text {
			width: 100%;
			line-height: 0.4rem;
			font-size: 12px;
			color: #ff9900;

			div {
				width: 100%;
			}
		}
	}

	.brand {
		.van-grid-item__content {
			padding: 0;
			padding-top: 10px;
			padding-left: 10px;
			padding-right: 10px;
			text-align: center;
		}
	}

	.title-class {
		color: #3487E2;
		font-size: 16px;
	}

	.value-class {
		font-size: 12px;
		color: #9A9A9A;
	}

	.title-class1 {
		font-size: 17px;
	}

	.value-class1 {
		font-size: 12px;
	}

	.my-color-gray {
		color: #9A9A9A;
	}

	.overhidden {
		overflow: hidden; //超出的文本隐藏

		text-overflow: ellipsis; //溢出用省略号显示

		// white-space:nowrap; //溢出不换行
	}

	.van-progress__pivot {
		display: none;
	}

	.h-right {
		text-align: center;

		.h-price {
			font-size: 16px;
			color: #3487E2;
		}

		.h-progress {
			width: 2rem;
			margin: 0.2rem 0 0.2rem 0;
		}

		.h-sale {
			font-size: 12px;
			color: gray;
		}

		.h-btn {
			font-size: 13px;
			background-color: #3487E2;
			color: white;
			margin-top: 0.1rem;
			padding-top: 0.1rem;
			padding-bottom: 0.05rem;
			border-radius: 0.1rem;
		}
	}



	// 积分礼品样式
	.score_l_box {
		display: flex;
		justify-content: space-around;
		padding: 15px;
	}

	.score_l_item {
		text-align: center;
		color: gray;

		.score_l_item_ming {
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-size: 12px;
		}

		.score_l_item_name {
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-size: 12px;
		}
	}



	// 这个是数量大于30时
	@keyframes turnleft_3 {
		from {
			left: -100%;
			top: 70px;
			opacity: 1;
		}

		10% {
			left: 20px;
			top: 70px;
		}

		15% {
			left: 20px;
			top: -40px;
		}

		80% {
			opacity: 0.8;
		}

		100% {
			left: 20px;
			top: -40px;
			opacity: 0;
		}
	}


	// 这个是数量大于10时
	@keyframes turnleft_1 {
		from {
			left: -100%;
			top: 70px;
			opacity: 1;
		}

		30% {
			left: 20px;
			top: 70px;
		}

		45% {
			left: 20px;
			top: -40px;
		}

		80% {
			opacity: 0.8;
		}

		100% {
			left: 20px;
			top: -40px;
			opacity: 0;
		}
	}

	//这个是数量少于10的
	@keyframes turnleft_2 {
		from {
			left: -100%;
			top: 50px;
			opacity: 1;
		}

		65% {
			left: 20px;
			top: 50px;
		}

		95% {
			left: 20px;
			top: -40px;
		}

		96% {
			opacity: 0.8;
		}

		100% {
			left: 20px;
			top: -40px;
			opacity: 0;
		}
	}
	
	.commit-modal {
		/deep/ textarea{
			padding: 0 10px;
		}
	}
	
	.avatar{
		border-radius: 50%;
		overflow: hidden;
	}
</style>
